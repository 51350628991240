/** @jsx jsx */
import HomePageLayout from '../../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Text_calltoaction from '../../../components/raw_templates/Text_calltoaction';
import Link from '@fify.at/gatsby-theme-base/src/components/Link';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';

export default function InFuenfSchrittenHausVerkaufen({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="In 5 Schritten zum erfolgreichen Hausankauf"
      description="Verkaufen Sie Ihr Haus schnell und einfach direkt vom Sofa aus. Wir zeigen Ihnen in 5 Schritten wie es geht"
      image={data.hero.childImageSharp.fluid}
      keywords={['Hausverkauf in 5 Schritten']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="In 5 Schritten zum erfolgreichen Hausankauf."
        subtitle="Einfach, schnell, diskret und sicher."
        image={data.hero}
      />
      <Section sx={{ textAlign: '' }} container="small">
        <div sx={{ marginBottom: 7 }}>
          <Heading>
            Wir kaufen Ihr Haus – für Sie einfach und bequem vom Sofa aus.
          </Heading>
          <Subheading>
            So unkompliziert und transparent funktioniert unser Ankaufsprozess
          </Subheading>
        </div>
        <p>
          Ein Hausverkauf ist zeitintensiv und erfordert Fachkenntnisse und
          Verhandlungsgeschick. WirkaufendeinHaus.at übernimmt das für Sie und
          kauft Ihr Haus!
        </p>
        <p>
          Die 5 wichtigsten Schritte für einen erfolgreichen Immobilienverkauf:
        </p>
        <h3>
          Schritt 1: Besichtigungstermin mit unseren regionalen Experten
          vereinbaren
        </h3>
        <p>
          Hinterlassen Sie in unserem{' '}
          <Link to="/terminbuchung/">Kontaktformular</Link> einfach Ihre
          Telefonnummer und einer unserer regionalen Immobilienexperten ruft Sie
          bezüglich eines <Link to="/terminbuchung/">Besichtigungstermins</Link>{' '}
          zurück. Sie sind Ihre Ansprechpartner für all Ihre Wünsche und
          Anliegen rund um den Hausverkauf.
        </p>
        <p>
          Ein erfahrenes, speziell im Bewertungsbereich ausgebildetes Team,
          ausgestattet mit modernsten Tools und Datenquellen, sorgt für eine
          punktgenaue Preisfindung Ihres Hauses. Unsere Experten stammen direkt
          aus Ihrer Region und treten nach außen hin diskret auf. Selbst Ihre
          Nachbarn werden es nicht mitbekommen: so vermeiden Sie Gerüchte in
          Ihrer Umgebung, wiederholte Anrufe von Maklern oder
          Scheininteressenten wie z.B. bei Privatinseraten auf diversen
          Plattformen.
        </p>
        <p>
          Dieser Service ist für Sie selbstverständlich 100% kostenlos und
          unverbindlich.
        </p>
        <h3>Schritt 2: Erstellen eines professionellen Bewertungsgutachtens</h3>
        <p>
          Nach Aufnahme aller relevanten Daten und Fakten, erstellt unser
          Experte gemeinsam mit einem professionellen Immobilienbewerter ein
          Verkehrswertgutachten Ihrer Immobilie. Hohe Fach- und Marktkenntnis
          gepaart mit dem normierten und gesetzlich vorgeschriebenen
          Bewertungsverfahren erlauben uns, Ihnen innerhalb von 48 Stunden nach
          Besichtigung der Immobilie ein rechtsverbindliches, kostenloses
          Kaufanbot zu legen, das wir Ihnen in einem 2. Termin präsentieren und
          genau mit Ihnen durchsprechen. Sie erfahren dabei den genauen
          Marktwert Ihres Hauses und können dann in Ruhe entscheiden, ob Sie es
          in den folgenden Tagen annehmen wollen oder nicht.
        </p>
        <h3>Schritt 3: Keine Kosten bei Ablehnung des Angebots</h3>
        <p>
          Wenn Sie unser Angebot nicht annehmen möchten, entstehen Ihnen keine
          Kosten. Gerne können Sie jedoch unser Bewertungsgutachten jederzeit
          käuflich erwerben. Der Preis richtet sich dabei nach Objekt und
          Aufwand und wird direkt mit unserem Immobilienbewerter vor Ort
          vereinbart.
        </p>
        <h3>Schritt 4: Sie bleiben flexibel</h3>
        <p>
          Egal wie auch immer die Umstände sind, Sie bleiben flexibel: Sie
          wollen noch eine Zeit lang in Ihrem Haus wohnen oder es soll mit dem
          Hausverkauf besonders schnell gehen? Bitte geben Sie unserem
          Immobilienexperten Ihre Wünsche vor der Angebotsannahme vor Ort
          bekannt. Er wird es entsprechend Ihrer Wünsche im Angebot formulieren.
        </p>
        <h3>Schritt 5: Angebotsannahme und weitere Abwicklung</h3>
        <p>
          Haben Sie unser Angebot einmal angenommen, haben Sie die Garantie,
          dass wir Ihr Haus sicher kaufen – ohne Wenn und Aber (vorbehaltlich
          aller Genehmigungen*)! Lehnen Sie sich einfach zurück, denn wir
          übernehmen die gesamte Treuhhandabwicklung über den Notar/Rechtsanwalt
          für Sie. In nur wenigen Tagen schon können Sie den Kaufvertrag mit uns
          oder einem unserer Käufer unterschreiben.
        </p>
        <p sx={{ fontStyle: 'italic' }}>
          * Wir gehen davon aus, dass Ihr Haus genehmigt ist und eine gültige
          Benutzungserlaubnis vorliegt sowie keine offenen Bescheide vorhanden
          oder bekannt sind. Diese Unterlagen können Sie bereits vorab bei Ihrer
          Gemeinde anfordern, um die Abwicklung zu beschleunigen. Unsere Partner
          werden nach Angebotsannahme alles schnell überprüfen und mögliche,
          notwendige Schritte in die Wege leiten.
          <br />
          Sollten Ihnen in diesem Bereich Probleme bekannt sein, besprechen Sie
          dies bitte vorab mit unserem Immobilienexperten beim Ersttermin.
          Selbstverständlich helfen wir Ihnen gerne bei der Organisation
          fehlender Genehmigungen.
          <br />
          ACHTUNG! Ein Hausverkäufer haftet per Gesetz bis zu 15 Jahre dafür,
          dass sein Haus zum Zeitpunkt des Verkaufs genehmigt und
          benutzungsbewilligt ist. Das kann auch in einem Kaufvertrag nicht
          rechtsgültig ausgeschlossen werden. Wie Sie sich hier rechtlich
          absichern können, erfahren Sie direkt bei unserem Immobilienexperten.
        </p>
      </Section>
      <Text_calltoaction
        sectionBackground="muted"
        title="Sie wollen vorab schon wissen, was Ihr Haus wert ist?"
        text="<p>Dann nutzen Sie jetzt unseren Online Immobilienrechner!<br/>Nach Beantwortung von ein paar Fragen und Bekanntgabe der relevanten Daten erhalten Sie in wenigen Schritten eine erste Einschätzung, welchen Wert Ihre Immobilie bei einem eventuellen Verkauf in Ihrer Region erzielen kann.</p>"
        buttons={[
          {
            text:
              'Online Immobilienbewertung jetzt starten <i class="fal fa-arrow-right"></i>',
            to: '/online-immobilienbewertung/',
          },
        ]}
      />
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-fuenf-schritten" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
